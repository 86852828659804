import React, { Component } from 'react'

import SEO from '../../components/seo'

class ResourcesPage extends Component {

  render() {
    return (
      <>
        <SEO title="Resources" />

        <section className="products-content">
          <h1>Tutorials</h1>
        </section>

      </>
    )
  }
}

export default ResourcesPage